import { defineStore } from 'pinia';
import { getList } from 'src/api/Metadata';

interface MetaStoreState {
  meta?: any;
  settings: any;
}

export const useMetaStore = defineStore('meta', {
  state: (): MetaStoreState => ({
    meta: {},
    settings: {},
  }),
  actions: {
    async fetchAll() {
      const { data } = await getList({
        isActive: true,
        maxResultCount: 1000,
      });

      const metas = data.items ?? [];
      this.meta = {
        title:
          metas.filter((t) => t.category === 'meta' && t.name === 'title')[0]
            ?.content ?? '涓柊璧涘厠',
        meta: metas
          .filter((t) => t.category === 'meta' && t.name !== 'title')
          .map((t) => ({
            name: t.name,
            content: t.content,
          })),
        link: metas
          .filter((t) => t.category === 'link')
          .map((t) => ({
            rel: t.rel,
            href: t.href,
            type: t.type,
            sizes: t.sizes,
          })),
        script: metas
          .filter((t) => t.category === 'script')
          .map((t) => ({
            type: t.type,
            src: t.src,
          })),
      };

      const otherMetas = metas.filter((t) => t.category === 'other');
      for (const meta of otherMetas) {
        if (meta.name) this.settings[meta.name] = meta.content;
      }
    },
  },
});
